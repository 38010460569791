import React from 'react'
import Layout from 'components/Layout'
import SectionIntegrations from 'components/SectionIntegrations'
import SectionGetStarted from 'components/SectionGetStarted'
const ProductPage = ({ location }) => (
  <Layout
    title="Pentafile, Integra de forma simple en tus aplicaciones"
    description="Pentafile es una forma inteligente para gestionar grandes cantidades de archivos en tu organización, configura y usa la mejor alternativa"
    location={location}
  >
    <section className="section section-product-hero">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center">
            <h1 className="m-b-100">
              Gestionar archivos ahora es más fácil con Pentafile
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <h2 className="m-t-0">
              Desentraliza la carga y acceso a tus archivos
            </h2>
            <ul className="highlight-ul">
              <li>Carga archivos de multiples tipos</li>
              <li>Aplica niveles de seguridad en los accesos</li>
              <li>Tranforma las imagenes en tiempo de carga</li>
              <li>Cache para la distribución de contenido</li>
              <li>Administración centralizada</li>
              <li>Disponibilidad 99.9%</li>
              <li>Trazabilidad</li>
              <li>Estadística</li>
              <li>Y mucho más</li>
            </ul>
          </div>
          <div className="col-md-7">
            <div className="browser-container hidden-xs">
              <img src="/assets/images/elements/pentafile-header.png" />
              <img src="/assets/images/elements/pentafile-architecture.png" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section bg-soft-blue">
      <div className="container">
        <div className="row">
          <div className="col-md-6 push-sm-down">
            <img
              src="/assets/images/elements/visualize-data.png"
              width="100%"
              className="visualize-dashboards-illustrated"
            />
          </div>
          <div className="col-md-6">
            <h2 className="m-b-0">Pensado para funcionar en la nube &</h2>
            <h4 className="m-t-0">para minimizar tus costos</h4>
            <ul className="highlight-ul">
              <li>
                Pentafile se sostiene en AWS para garantizar el almanecamiento
                eficiente de tus archivos.
              </li>
              <li>Optimizado para una disponibilidad de 99.99%</li>
              <li>
                Pentafile tiene un sistema de Backup automatizado que garantiza
                la rápida recuperación
              </li>
              <li>
                Soporte de nuestros especialistas para ayudarte a desentralizar tu archivos
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <div className="row">
          <h2 className="m-t-0 flex-content-center flex">
            Mecanismos de integración a Pentafile
          </h2>
          <br />
        </div>
        <div className="row">
          <div className="col-lg-12 col-xs-12 p-0 flex-content-center">
            <div className="col-xs-12 col-sm-4 col-md-4">
              <div className="card-content">
                <img
                  src="/assets/images/elements/icon-alerts.png"
                  width="64px"
                />
                <h2>SDK</h2>
                <p>
                  Para múltiples lenguajes, configura y sube tus archivos
                  fácilmente.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <div className="card-content">
                <img
                  src="/assets/images/elements/icon-alerts.png"
                  width="64px"
                />
                <h2>API</h2>
                <p>
                  Amigable API para integrarlo tu mismo en tus aplicaciones.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <div className="card-content">
                <img
                  src="/assets/images/elements/icon-alerts.png"
                  width="64px"
                />
                <h2>Componente UI</h2>
                <p>
                  Listo para usar en tu interface de usuario en simples pasos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <SectionIntegrations />
    <SectionGetStarted />
  </Layout>
)
export default ProductPage
